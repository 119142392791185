import React from 'react';
import styled from 'styled-components';

const TutorialContainer = styled.div`
  background-color: #44475a;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const TutorialTitle = styled.h2`
  color: #50fa7b;
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const TutorialStep = styled.div`
  margin-bottom: 1.5rem;
`;

const StepTitle = styled.h3`
  color: #ff79c6;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
`;

const StepDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;

const CodeSnippet = styled.pre`
  background-color: #282a36;
  color: #f8f8f2;
  padding: 1rem;
  border-radius: 8px;
  overflow-x: auto;
  font-family: 'Courier New', Courier, monospace;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
`;

const Th = styled.th`
  background-color: #6272a4;
  color: #f8f8f2;
  padding: 0.5rem;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #6272a4;
  padding: 0.5rem;
`;

function Tutorial() {
  const codeExample = `
<form action='/'>
  <input type='password' id='password' name='password'>
  <input type='submit' value='Submit'>
</form>
  `;
  
  const codeExplanation = [
    { element: 'form', description: 'Creates a form that submits data to the root path (/)' },
    { element: 'input type="password"', description: 'Password field' },
    { element: 'input type="submit"', description: 'Submit button to send the form data' },
  ];
  
  return (
    <TutorialContainer>
    <TutorialTitle>Build a Good Captive</TutorialTitle>
    <StepTitle>Code Data Entry Form</StepTitle>
    <CodeSnippet>{codeExample}</CodeSnippet>
    <StepTitle>Code Explanation</StepTitle>
    <Table>
    <thead>
    <tr>
    <Th>Element</Th>
    <Th>Description</Th>
    </tr>
    </thead>
    <tbody>
    {codeExplanation.map((item, index) => (
      <tr key={index}>
      <Td>{item.element}</Td>
      <Td>{item.description}</Td>
      </tr>
    ))}
    </tbody>
    </Table>
    <StepDescription>
    You must include this code, ensure that all information 
    is transmitted and stored.
    </StepDescription>
    </TutorialContainer>
  );
}

export default Tutorial;