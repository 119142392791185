// App.js
import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import ProductDescription from './components/ProductDescription';
import Features from './components/Features';
import Tutorial from './components/Tutorial';
import Screenshots from './components/Screenshots';
import Footer from './components/Footer';
import './global.css';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  animation: ${fadeIn} 1s ease-out;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Header = styled.header`
  background-color: #282a36;
  padding: 4rem 0 6rem; // Increased bottom padding
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 3rem; // Add margin to create gap

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(255, 121, 198, 0.1),
      rgba(189, 147, 249, 0.1),
      rgba(139, 233, 253, 0.1),
      rgba(80, 250, 123, 0.1)
    );
    background-size: 400% 400%;
    animation: ${gradientAnimation} 15s ease infinite;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(40, 42, 54, 0), #282a36);
    z-index: 2;
  }
`;

const HeaderContent = styled.div`
  position: relative;
  z-index: 3;
`;

const HeaderTitle = styled.h1`
  font-size: 8rem;
  margin: 0;
  background: linear-gradient(270deg, #ff79c6, #bd93f9, #8be9fd);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -moz-background-clip: text;
  animation: ${gradientAnimation} 4s ease infinite;

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`;

const NavBar = styled.nav`
  background-color: rgba(40, 42, 54, 0.8);
  position: sticky;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
`;

const NavList = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(68, 71, 90, 0.8);
  }
`;

function App() {
  useEffect(() => {
    const sections = document.querySelectorAll('.section');
    
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => {
      sections.forEach(section => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div>
    <NavBar>
    <NavList>
    <NavItem onClick={() => document.getElementById('product').scrollIntoView({ behavior: 'smooth' })}>Product</NavItem>
    <NavItem onClick={() => document.getElementById('features').scrollIntoView({ behavior: 'smooth' })}>Features</NavItem>
    <NavItem onClick={() => document.getElementById('tutorial').scrollIntoView({ behavior: 'smooth' })}>Tutorial</NavItem>
    <NavItem onClick={() => document.getElementById('screenshots').scrollIntoView({ behavior: 'smooth' })}>Screenshots</NavItem>
    </NavList>
    </NavBar>
      <Header>
        <HeaderTitle>HELLISH</HeaderTitle>
      </Header>
      <Container>
        <section id="product" className="section">
          <ProductDescription />
        </section>
        <section id="features" className="section">
          <Features />
        </section>
        <section id="tutorial" className="section">
          <Tutorial />
        </section>
        <section id="screenshots" className="section">
          <Screenshots />
        </section>
      </Container>
      <Footer />
    </div>
  );
}

export default App;