import React from 'react';
import styled from 'styled-components';

const ScreenshotsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
`;

const Screenshot = styled.img`
  width: 400px;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
  }
`;

const SectionTitle = styled.h2`
  color: #50fa7b;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
`;

function Screenshots() {
  return (
    <div>
      <SectionTitle>HELLISH Screenshot</SectionTitle>
      <ScreenshotsContainer>
        <Screenshot src="/img/hellish_main.png" alt="Product Screenshot 1" />
        <Screenshot src="/img/hellish_settings.png" alt="Product Screenshot 2" />
      </ScreenshotsContainer>
    </div>
  );
}

export default Screenshots;