// Footer.js
import React from 'react';
import styled, { keyframes } from 'styled-components';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const FooterContainer = styled.footer`
  background-color: #282a36;
  color: #f8f8f2;
  padding: 3rem 0;
  margin-top: 3rem;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(255, 121, 198, 0.05),
      rgba(189, 147, 249, 0.05),
      rgba(139, 233, 253, 0.05),
      rgba(80, 250, 123, 0.05)
    );
    background-size: 400% 400%;
    animation: ${gradientAnimation} 15s ease infinite;
    z-index: 1;
  }
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
  position: relative;
  z-index: 2;
`;

const DisclaimerText = styled.p`
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const CopyrightText = styled.p`
  font-size: 0.9rem;
  opacity: 0.8;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <DisclaimerText>
          Disclaimer: This software is provided for educational and research purposes only. The creators and distributors of this tool do not condone or encourage any illegal or unethical use of this software. Users are solely responsible for ensuring that their use of this tool complies with all applicable laws, regulations, and ethical standards. We explicitly disclaim any liability for any misuse or damage caused by the application of this software in public or private networks without proper authorization.
        </DisclaimerText>
        <CopyrightText>
          © {new Date().getFullYear()} HELLISH. All rights reserved.
        </CopyrightText>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;