import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #44475a;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const Title = styled.h2`
  color: #ff79c6;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ShopsTitle = styled.h3`
  color: #50fa7b;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const ShopLinks = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

const ShopLink = styled.a`
  background-color: #6272a4;
  color: #f8f8f2;
  padding: 1rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff79c6;
  }
`;

function ProductDescriptionAndShops() {
  return (
    <Container>
      <Title>About</Title>
      <Description>
        Network management tool, complete with a sleek and user-friendly web interface. It creates an Access Point where you can manage connections, explore network environments, and experiment with various network functionalities.
      </Description>
      <ShopsTitle>Buy Now</ShopsTitle>
      <ShopLinks>
        <ShopLink href="https://github.com/malvidous/HELLISH" target="_blank" rel="noopener noreferrer">GitHub</ShopLink>
        <ShopLink href="https://www.tokopedia.com/fgrip/deauther-eviltwin-wifi-bobol-password-siap-pakai-esp8266-wemos-nodemcu-iot-led?utm_campaign=pdp-frdtvd6nyld5-14603684152-0&utm_source=salinlink&utm_medium=share">Tokopedia</ShopLink>
      </ShopLinks>
    </Container>
  );
}

export default ProductDescriptionAndShops;