import React from 'react';
import styled from 'styled-components';

const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureBox = styled.div`
  background-color: #44475a;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
`;

const FeatureTitle = styled.h3`
  color: #8be9fd;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;

const SectionTitle = styled.h2`
  color: #50fa7b;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
`;

function Features() {
  const features = [
    { title: 'Deauth', description: 'This operation specifically targets the access point (AP) by sending carefully crafted deauthentication (0xC0) and disassociation (0xA0) packets. Each packet is dispatched at 100ms intervals, ensuring a consistent and effective disruption. This process is integrated into a larger loop that includes additional functionality.' },
    { title: 'Aggressive Deauth', description: 'The aggressive deauth will target both the access point (using its BSSID) and a broadcast address for clients. The aggressive deauth will target both the access point (using its BSSID) and a broadcast address for clients. This function sends four packets for each deauth attempt Deauth to client (from AP), Deauth to AP (from client), Disassociation to client (from AP), Disassociation to AP (from client).' },
    { title: 'EvilTwin', description: 'In tandem, an EvilTwin is deployed, creating a rogue access point that mimics the legitimate one. Unsuspecting users connect to this rogue AP, believing it to be the genuine network. This setup allows for the interception of data and potential access to sensitive information. The combination of deauthing and the Evil Twin attack provides a powerful and comprehensive approach to network manipulation.' },
    { title: 'Kill-All', description: 'This operation aims to disrupt all networks within range by sending deauthentication (0xC0) and disassociation (0xA0) packets. These packets are leading to a temporary disconnection of devices from the affected networks. Integrated into a larger loop with other functionalities, this approach ensures a consistent and widespread impact, all while maintaining a methodical and controlled execution.' },
  ];

  return (
    <div>
      <SectionTitle>Main Features</SectionTitle>
      <FeaturesContainer>
        {features.map((feature, index) => (
          <FeatureBox key={index}>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureBox>
        ))}
      </FeaturesContainer>
    </div>
  );
}

export default Features;